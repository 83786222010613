import { ReactNode } from "react";

export type RouteType = {
  element: ReactNode,
  state: string,
  index?: boolean,
  path?: string,
  child?: RouteType[],
  sidebarProps?: {
    displayText: string,
    icon?: ReactNode,
  },
};
export const socketUrl = "http://192.168.0.117:8000";
// export const socketUrl = "https://backendv2.agrexai.com";
// export const BACKEND_URL = "https://staging.agrexai.com/api/v2";
// export const BACKEND_URL = "http://192.168.0.26:8000/api/v2";
export const BACKEND_URL = "https://staging.agrexai.com/api/v2";
// export const BACKEND_URL = "http://192.168.0.165:8000/api/v2";
//
export const LIVEVIEW_SERVER_URL = "https://lv.agrexai.com";
